.exo2 {
  font-family: "Exo 2" !important;
}

@media print {
  .mg {
    margin-bottom: 10px;
    margin-top: 10px;
  }
}
