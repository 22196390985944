.App {
  font-family: 'Exo 2';
}

.exo2 {
  font-family: "Exo 2" !important;
}

.text-bold {
  font-weight: 700;
}