@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;800&display=swap");

.font1 {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

.font1-bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 800;
}

.font2 {
  font-family: "Arial Narrow", sans-serif;
  font-weight: 400;
}

.font2-bold {
  font-family: "Arial Narrow", sans-serif;
  font-weight: 800;
}
